<template>
  <div class="columns p-3 m-0">
    <div class="column p-0 m-0">
      <!-- Heading and Buttons -->
      <div class="columns is-vcentered p-0 m-0 pb-2 bb-primary-1">

        <div class="column p-0 m-0  is-size-6 is-5 ">
          <slot name="heading" />
        </div>

        <div class="column p-0 m-0 is-size-6 is-2">
          <slot name="heading-price" />
        </div>
        <!--
        <div class="column p-0 m-0 has-text-primary is-size-2 is-2">
          <slot name="tabs" />
        </div> -->

        <div class="column p-0 m-0">
          <div class="is-flex is-flex-direction-row-reverse">
            <slot name="heading_buttons" />
          </div>
        </div>

      </div>

      <!-- Subheading -->
      <div class="columns p-0 m-0">
        <div class="column has-text-primary p-0 m-0 is-2">
          <slot name="subheading" />
        </div>

        <div class="column p-0 m-0 is-1">
          <slot name="invoices" />
        </div>

      </div>

      <!-- Body -->
      <div class="columns p-0 m-0">
        <div class="column p-0 m-0">
          <slot name="body" />
        </div>
      </div>

      <!-- Footer -->
      <div class="columns p-0 m-0 pt-2">
        <div class="column p-0 m-0">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuotePartsControlSection'
}
</script>

<style lang="scss">
@import '../../../assets/style/themes/default/_variables.scss';
.bb-primary-1 {
  border-bottom: 1px solid $primary;
}
</style>