<template>
  <quote-parts-control-section :key="purchaseOrder.purchaseOrderId">
    <template slot="heading">
      <span class="has-text-weight-bold"
        :class="{'has-text-success' : purchaseOrder.partsCheckPurchaseOrderNo !== ''}">
        Supplier: {{ purchaseOrder.vendor.name }} - Ordered: {{ purchaseOrder.purchaseOrderDate | formatDate($userInfo.locale) }}
      </span>
    </template>

    <template slot="heading-price">
      <span class=" has-text-weight-bold">
        {{ $filters.formatCurrency(addTotal(getPurchaseOrderLines(purchaseOrder), 'qtePrice') , $userInfo.locale) }}
      </span>
    </template>

    <!-- <template slot="tabs">
      <div class="toggle-buttons-vertical ml-2 is-flex is-flex-direction-row">
        <div class="button m-0"
          style="border-radius: 5px 0px 0px 5px;"
          :class="{ 'is-primary is-selected' : viewMode === viewModeEnums.parts}"
          @click="viewMode = viewModeEnums.parts">
          <span>Parts</span>
        </div>
        <div class="button m-0 "
          style="border-radius: 0px 5px 5px 0px;"
          :class="{ 'is-primary is-selected' : viewMode === viewModeEnums.receipts, 'disabled' : !purchaseOrder.orderReceipts?.length}"
          @click="viewMode = viewModeEnums.receipts">
          <span>Receipts</span>
          <span v-show="!!purchaseOrder.orderReceipts?.length"
            class="has-badge-rounded has-badge-small"
            style="top: -20px; right: -10px;"
            :data-badge="purchaseOrder.orderReceipts?.length" />
        </div>
      </div>
    </template> -->

    <template slot="heading_buttons"
      v-if="!isPartsCheckPurchaseOrder">
      <div class="button ml-2 is-primary"
        :id="`return-items-` + id"
        :class=" !isReturnItemsClickable ? 'disabled is-outlined' : 'is-primary'"
        @click="e => changeItemStatus(getPurchaseOrderLines(purchaseOrder), partsControlEnums.return.key, e)">
        Return Items
      </div>
      <div class="button ml-2 is-primary "
        :id="`recieve-items-` + id"
        :class=" !isReceiveItemsClickable ? 'disabled is-outlined' : 'is-primary'"
        @click="e => changeItemStatus(getPurchaseOrderLines(purchaseOrder), partsControlEnums.receive.key, e)">Receive Items</div>
      <div class="button ml-2 is-primary"
        :id="`cancel-items-` + id"
        :class=" !isCancelItemsClickable ? 'disabled is-outlined' : ' is-primary'"
        @click="e => changeItemStatus(getPurchaseOrderLines(purchaseOrder), partsControlEnums.cancel.key, e)">Cancel Items</div>
    </template>

    <template slot="empty">
      <section class="section">
        <div class="content has-text-grey has-text-centered">
          <span icon="icon is-large">
            <i class="mdi mdi-48px mdi-emoticon-sad" />
          </span>
          <p>Nothing</p>
        </div>
      </section>
    </template>

    <template slot="subheading">
      <div style="padding: 5px 0px 5px 0px !important;"
        :class="purchaseOrder.partsCheckPurchaseOrderNo == '' ? '' : 'has-text-success'">
        {{ purchaseOrder.partsCheckPurchaseOrderNo == "" ? 'Manual Purchase Order. ' : 'PartsCheck Purchase Order. ' }}
        <span class=" has-text-weight-semibold"
          :class="{'is-underlined has-text-success is-clickable': purchaseOrder.partsCheckPurchaseOrderNo !== ''}"
          @click="purchaseOrder.partsCheckPurchaseOrderNo !== '' ? goToPartsCheck(purchaseOrder.purchaseOrderNo, 'po') : ''">
          {{ purchaseOrder.partsCheckPurchaseOrderNo != "" ? purchaseOrder.partsCheckPurchaseOrderNo : purchaseOrder.purchaseOrderNo }}
        </span>
      </div>
    </template>

    <template slot="invoices"
      v-if="!!purchaseOrder.orderReceipts?.length">
      <div style="cursor: pointer;"
        class="has-text-weight-bold mt-1">
        Invoices:
        <!-- <span v-show="!!purchaseOrder.orderReceipts?.length"
          class="has-badge-rounded has-badge-small"
          style="top: 0px; right: -1px;"
          :data-badge="purchaseOrder.orderReceipts?.length" /> -->
        <span class="has-text-weight-bold has-text-link">{{ purchaseOrder.orderReceipts?.length }}</span>
      </div>
      <tippy arrow
        :interactive="true"
        placement="top"
        :theme="`light`"
        :trigger="`click`">
        <div style="min-width: 500px;">
          <div style="width: 80%;"
            class="left">
            <span class="has-text-weight-bold ml-1">
              Invoice No.
            </span>
          </div>
          <!-- <div style="width: 10%;">Invoice Date.</div>
          <div style="width: 10%;">Parts</div>
          <div style="width: 10%;">Qty</div>
          <div style="width: 10%;">Received</div>
          <div style="width: 10%;">Returned</div>
          <div style="width: 15%;">Amount</div> -->
        </div>

        <div class="is flex is-flex-row "
          :class="{'disabled' : isPartsCheckPurchaseOrder}"
          :title="isPartsCheckPurchaseOrder ? 'Parts Check Purchase Orders cannot be edited here' : ''">
          <div class="w-100 part-row is-flex is-flex-row"
            v-for="(item, index) in purchaseOrder.orderReceipts"
            :key="item.invoiceNo + index">

            <div style="width: 80%;"
              class="left">
              <span class="ml-2">
                {{ item.invoiceNo }}
              </span>
            </div>
            <!--
            <div style="width: 10%;">
              {{ $filters.formatDateLocale(item.invoiceDate, $userInfo.locale, 'short') }}
            </div>

            <div style="width: 10%;">
              {{ item.receiptItems?.length || 0 }}
            </div>

            <div style="width: 10%;">
              {{ item.receiptItems?.reduce((acc, receipt) => acc + receipt.orderQty, 0) || 0 }}
            </div>

            <div style="width: 10%;">
              {{ getTotalReceivedQty(item.receiptItems) || 0 }}
            </div>

            <div style="width: 10%;">
              {{ getTotalReturnedQty(item.receiptItems) || 0 }}
            </div>

            <div style="width: 15%;">
              {{ $filters.formatCurrency(item.overrideIncGst , $userInfo.locale) }}
            </div> -->

            <div style="width: 20%;"
              class="right">
              <div class="button ml-2 is-primary is-outlined px-2"
                @click="handleEditOrder(item)"
                :id="`edit-invoice-` + item.invoiceNo">
                Edit
              </div>

              <div class="button ml-2 is-primary is-outlined px-2 mr-4"
                @click="handleReturnOrder(item)"
                :id="`edit-invoice-` + item.invoiceNo">
                Return
              </div>
            </div>

          </div>
        </div>
      </tippy>
    </template>

    <!----------------------------------------- Parts table section --------------------------------------->
    <template slot="body">
      <div class="header parts-order-grid"
        :title="isPartsCheckPurchaseOrder ? 'Parts Check Purchase Orders cannot be edited here' : ''"
        v-if="viewMode === viewModeEnums.parts">
        <div class="center">#</div>
        <div class="center">
          <div class="field has-checkradio"
            style="margin-top: -20px; margin-left: 10px;">
            <div class="control"
              :class="{'disabled' : isPartsCheckPurchaseOrder}">
              <input class="is-checkradio is-primary"
                :class="{ 'has-background-color': areAllChecked }"
                :id="`child-checkbox-` + id"
                @click="titleCheckboxChanged"
                :name="`child-checkbox-` + id"
                :checked="areAllChecked"
                type="checkbox">
              <label :for="`child-checkbox-` + id" />
            </div>
          </div>
        </div>

        <div class="left">Description</div>
        <div>Part No</div>
        <div>Mark</div>
        <div>Auth</div>
        <div>Sup</div>
        <div>Qty</div>
        <div>Ord</div>
        <div>Rec</div>
        <div>Ret</div>
        <div class="right">Unit Price</div>
        <div class="right">Qte Price</div>
        <div>Status</div>
      </div>

      <div class="is flex is-flex-row "
        :title="isPartsCheckPurchaseOrder ? 'Parts Check Purchase Orders cannot be edited here' : ''"
        v-if="viewMode === viewModeEnums.parts">
        <div :id="index + `-child-checkbox-` + id"
          v-for="(item, index) in getPurchaseOrderLines(purchaseOrder)"
          :key="item.purchaseOrderItemId"
          class="part-row parts-order-grid"
          :class="{ 'item-deleted': item.deleted, 'disable-pointer-events': !!isPartsCheckPurchaseOrder }"
          @click="handleSelectItem(item)">

          <!-- line through when deleted -->
          <div class="line-through"
            v-if="!!item.deleted" />

          <div class="center">{{ index + 1 }}</div>

          <div class="center">
            <div class="field has-checkradio"
              style="margin-top: -20px; margin-left: 10px;">
              <div class="control"
                :class="{'disabled' : isPartsCheckPurchaseOrder}">
                <input class="is-checkradio is-primary"
                  :class="{ 'has-background-color': selectedRows.includes(item.purchaseOrderItemId) }"
                  @click="handleSelectItem(item)"
                  :id="`child-checkbox-` + index + '-' + id"
                  :name="`child-checkbox-` + index + '-' + id"
                  :checked="selectedRows.includes(item.purchaseOrderItemId)"
                  type="checkbox">
                <label for="`child-checkbox-` + index + '-' + id" />
              </div>
            </div>
          </div>

          <div class="left">{{ item.description }}</div>
          <div>{{ item.partNo }}</div>
          <div>{{ item.mark }}</div>
          <div>Auth</div>
          <div>Sup</div>
          <div>{{ item.quotedItemQty }}</div>
          <div>{{ item.quantity }}</div>
          <div>{{ item.receivedQty }}</div>
          <div>{{ item.returnQty }}</div>
          <div class="right">{{ $filters.formatCurrency(item.unitPrice , $userInfo.locale) }}</div>
          <div class="right">{{ $filters.formatCurrency(item?.qtePrice , $userInfo.locale) }}</div>
          <div>
            <span :class="[getItemStatus(item)?.classStatus]"
              :title="getItemStatus(item)?.title" />
          </div>
        </div>
      </div>

      <!---------------------------------------------- Receipt Section  ------------------------------------>

    </template>
  </quote-parts-control-section>
</template>

<script>
import QuotePartsControlSection from '../QuotePartsControlSection.vue'
import { customFormatDateLocale } from '@/utils/DateFunctions'
import partsControlEnums from '../parts-control-enums'
import { PartsCheckService } from '../../services'

const viewModeEnums = {
  parts: 'parts',
  receipts: 'receipts'
}

export default {
  name: 'QuotePartsControlOrdered',
  components: { QuotePartsControlSection },
  filters: {
    formatDate: function (value, locale) {
      const options = {
        weekday: 'short',
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      }
      return customFormatDateLocale(value, locale, options)
    }
  },
  props: {
    value: null,
    purchaseOrder: {
      type: Object,
      default: () => ({})
    },
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      viewMode: viewModeEnums.parts,
      openSubRows: [],
      purchaseOrders: [],
      activePartsCheckRequest: 0,
      partsCheckPrices: [],
      selectedRows: []
    }
  },
  computed: {
    viewModeEnums() {
      return viewModeEnums
    },
    quotes() {
      return [this.value]
    },
    areAllChecked() {
      return this.getPurchaseOrderLines(this.purchaseOrder)?.length === this?.selectedRows?.length
    },
    partsControlEnums() {
      return partsControlEnums
    },
    isReceiveItemsClickable() {
      if (this.selectedRows.length === 0) return false
      const selectedItems = this.getPurchaseOrderLines(this.purchaseOrder).filter((item) => this.selectedRows.includes(item.purchaseOrderItemId))
      const isclickable = selectedItems.every((item) => item.quantity > item.receivedQty - item.returnQty)
      return isclickable
    },
    isReturnItemsClickable() {
      if (this.selectedRows.length === 0) return false
      const selectedItems = this.getPurchaseOrderLines(this.purchaseOrder).filter((item) => this.selectedRows.includes(item.purchaseOrderItemId))
      const isclickable = selectedItems.every((item) => item.receivedQty > 0)
      return isclickable
    },
    isCancelItemsClickable() {
      if (this.selectedRows.length === 0) return false
      const selectedItems = this.getPurchaseOrderLines(this.purchaseOrder).filter((item) => this.selectedRows.includes(item.purchaseOrderItemId))
      const isclickable = selectedItems.every((item) => item.receivedQty == 0)
      return isclickable
    },
    isPartsCheckPurchaseOrder() {
      return this.purchaseOrder.partsCheckPurchaseOrderNo !== ''
    }
  },
  methods: {
    getItemStatus(item) {
      let classStatus = 'mdi mdi-22px'
      let title = ''
      //// fully received
      if (item.receivedQty === item.quantity && !item.returnQty) {
        classStatus += ' mdi-package-variant-closed-check has-text-success'
        title = 'Fully Received'
        //// fully returned
      } else if (item.returnQty === item.quantity) {
        classStatus += ' mdi-arrow-left-box has-text-primary'
        title = 'All items Returned'
        //// partially received
      } else if (item.receivedQty > 0 && !item.returnQty) {
        classStatus += ' mdi-package-variant-closed-check has-text-warning'
        title = 'Partially Received'
        //// partially returned
      } else if (item.returnQty > 0) {
        classStatus += ' mdi-arrow-left-box has-text-warning'
        title = 'Partially Returned'
        //// calceled or credited
      } else if (item.deleted) {
        classStatus += ' mdi-close-circle has-text-danger'
        title = 'Item Cancelled or Credited'
      } else {
        //// part ordered and no changes made since
        classStatus += ' mdi mdi-truck has-text-success'
        title = 'item Ordered'
      }
      return { classStatus, title }
    },
    handleSelectItem(item) {
      if (this.selectedRows.includes(item.purchaseOrderItemId)) {
        this.selectedRows = this.selectedRows.filter((row) => row !== item.purchaseOrderItemId)
      } else {
        this.selectedRows.push(item.purchaseOrderItemId)
      }
    },
    addTotal(arr, key) {
      return arr.reduce((a, b) => a + (b[key] || 0), 0)
    },
    getPurchaseOrderLines(purchaseOrder) {
      return [...purchaseOrder.parts, ...purchaseOrder.sublets, ...purchaseOrder.miscs, ...purchaseOrder.otherPurchases]
    },
    titleCheckboxChanged() {
      if (this.areAllChecked) {
        this.selectedRows = []
      } else {
        this.selectedRows = this.getPurchaseOrderLines(this.purchaseOrder).map((item) => item.purchaseOrderItemId)
      }
    },
    changeItemStatus(array, status, e) {
      const selectedItems = this.getPurchaseOrderLines(this.purchaseOrder).filter((item) => this.selectedRows.includes(item.purchaseOrderItemId))
      this.$emit('changeItemStatus', status, this.purchaseOrder, selectedItems, this.id)
    },
    async goToPartsCheck(partsCheckReferenceNo, searchType) {
      const quoteNo =
        this.value.subQuoteNo == 0
          ? this.value.prefix + this.value.quoteNo + this.value.suffix
          : this.value.prefix + this.value.quoteNo + this.value.suffix + '/' + this.value.subQuoteNo
      let pQuoteNo = partsCheckReferenceNo == '0' ? quoteNo : quoteNo + '#' + partsCheckReferenceNo
      pQuoteNo = encodeURIComponent(pQuoteNo)

      const result = await PartsCheckService.goToPartsCheck(pQuoteNo, searchType)
      if (result) window.open(result, '_blank')
    },
    getTotalReceivedQty(receipts) {
      return receipts.reduce((acc, receipt) => acc + receipt.received, 0)
    },
    getTotalReturnedQty(receipts) {
      return receipts.reduce((acc, receipt) => acc + receipt.returnedQty, 0)
    },
    handleEditOrder(item) {
      this.$emit('handleEditOrder', item, this.purchaseOrder)
    },
    handleReturnOrder(item) {
      this.$emit('handleReturnOrder', item, this.purchaseOrder)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/style/themes/default/_variables.scss';

.parts-order-grid {
  display: grid;
  // headings:   index, chk, des, pNo, mark, auth, sup, qty, ord, rec, ret, unit price, qte price, status
  grid-template-columns: 3% 5% 25% 10% 10% 5% 5% 3% 3% 3% 3% 10% 10% 5%;
}
</style>

<style >
.tippy-box {
  min-width: unset !important;
}
</style>
