<template>
  <div class="columns">
    <div class="column p-0 m-0">
      <div class="tile is-ancestor is-parent p-0 m-0">
        <div class="tile p-0 m-0">
          <div class="tile is-parent is-vertical p-0 m-0 full-quote-page-height">

            <article class="tile is-child box detail-page-tile p-0 m-0"
              v-if="!partsStatusScreen.show && !purchaseOrderScreen.show">
              <div class="columns p-0 m-0 sticky-heading"
                style="margin-top: 12px !important">
                <div class="column p-0 m-0 is-full ">
                  <div class="px-3 is-size-4 has-text-weight-bold"
                    style="border-bottom: 1px solid;">Parts Control - Not Ordered
                  </div>
                </div>
              </div>

              <!-- <div v-if="true">
                <div class="tabs">
                  <ul>
                    <li v-for="(request,index) in partsCheckPrices"
                      :key="request.draftID_PK"
                      :class="{'is-active': activePartsCheckRequest == index}"
                      @click="activePartsCheckRequest = index"><a>Request {{ index +1 }}</a></li>
                  </ul>
                </div>
                <template v-for="(request,index) in partsCheckPrices">
                  <div :key="request.draftID_PK"
                    v-if="index==activePartsCheckRequest">
                    <div>Request {{ index +1 }}</div>
                    <div class="button is-primary is-outlined"
                      @click="savePartsCheckRequest(request)">Save</div>
                    <div class="button is-primary is-outlined"
                      @click="purchasePartsCheckRequest(request)">Purchase</div>
                    <table border="1">
                      <tr>
                        <td>xxxx</td>
                        <td v-for="supplier in getOrderedSupplierList(request)"
                          :key="supplier.clientID_PK">{{ supplier.clientName }}</td>
                      </tr>
                      <tr v-for="line in request.tableData.idxLineItems.lines"
                        :key="line.draftItemID">
                        <td>{{ line.importPartDescription }}</td>
                        <template v-for="supplier in getOrderedSupplierList(request)">
                          <td :key="supplier.clientID_PK"
                            :class="{'has-background-danger': line?.poPriceID == getPriceForCell(line, supplier)?.price?.priceID_PK,'has-background-info': line.poID == null && getPriceForCell(line, supplier)?.price?.isSelected == '1'}"
                            @click="togglePriceSelection(line,supplier)">
                            {{ getPriceForCell(line, supplier)?.price?.priceValue }}
                          </td>
                        </template>
                      </tr>
                    </table>
                  </div>
                </template>
              </div> -->

              <!-- Unordered parts -->
              <quote-parts-control-not-ordered :value="value"
                :snapshot-diff="snapshotDiff"
                @newPurchaseOrder="(itemsToPurchase) => handleCreateNewPurchaseOrder(itemsToPurchase)"
                @goToPriceParts="goToPriceParts" />

              <div class="columns p-0 m-0 sticky-heading"
                style="margin-top: 12px !important">
                <div class="column p-0 m-0 is-full ">
                  <div class="px-3 is-size-4  has-text-weight-bold"
                    style="border-bottom: 1px solid;">
                    Parts Control - Ordered
                  </div>
                </div>
              </div>

              <!-- Ordered parts -->
              <div v-for="(purchaseOrder, index) in purchaseOrders"
                :key="index + '-parts-control-ordered-parts-group' + purchaseOrder.purchaseOrderId"
                :id="index + '-parts-control-ordered-parts-group' + purchaseOrder.purchaseOrderId">
                <quote-parts-control-ordered :value="value"
                  :id="index + '-parts-control-ordered-parts-group' + purchaseOrder.purchaseOrderId"
                  :key="index + '-parts-control-ordered-parts-group'"
                  :purchase-order="purchaseOrder"
                  @handleReturnOrder="(orderReceipt, purchaseOrder) => handleReturnOrder(orderReceipt, purchaseOrder)"
                  @handleEditOrder="(orderReceipt, purchaseOrder) => handleEditOrder(orderReceipt, purchaseOrder)"
                  @changeItemStatus="changeItemStatus" />
              </div>
            </article>

            <!-- screens to make new pos, recieve, return and cancel items -->
            <article class="tile is-child box detail-page-tile p-0 m-0"
              v-else>

              <!-- New purchase Order -->
              <parts-control-new-purchase-order v-if="purchaseOrderScreen.show"
                :quote="purchaseOrderScreen.quote"
                :items-to-purchase="purchaseOrderScreen.itemsToPurchase"
                :quote-id="value.quoteId"
                :quote-no="value.quoteNo"
                :insurer-id="value.insurerId"
                :customer-id="value.customerId"
                @close="(message, refreshQuote = false) => handleOnClose(message, refreshQuote)" />

              <!-- Receive Parts -->
              <parts-control-recieve-parts v-if="partsStatusScreen.status == partsControlEnums.receive.key"
                :purchase-order="partsStatusScreen.purchaseOrder"
                :order-receipt="partsStatusScreen.orderReceipt"
                :selected-items="partsStatusScreen.selectedItems"
                @close="(message) => handleOnClose(message)" />

              <!-- Cancel Parts -->
              <parts-control-cancel-parts v-if="partsStatusScreen.status == partsControlEnums.cancel.key"
                :purchase-order="partsStatusScreen.purchaseOrder"
                :selected-items="partsStatusScreen.selectedItems"
                @close="(message) => handleOnClose(message)" />

              <!-- Return Parts -->
              <parts-control-return-parts v-if="partsStatusScreen.status == partsControlEnums.return.key"
                :purchase-order="partsStatusScreen.purchaseOrder"
                :order-receipt="partsStatusScreen.orderReceipt"
                :selected-items="partsStatusScreen.selectedItems"
                @close="(message) => handleOnClose(message)" />
            </article>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PurchaseOrderService, PartsCheckService } from '@/services'
import { customFormatDateLocale } from '@/utils/DateFunctions'
import QuotePartsControlNotOrdered from './unorderedparts/QuotePartsControlNotOrdered.vue'
import QuotePartsControlOrdered from './orderedParts/QuotePartsControlOrdered.vue'
import partsControlEnums from './parts-control-enums'
import PartsControlRecieveParts from './orderControlScreens/PartsControlRecieveParts.vue'
import PartsControlCancelParts from './orderControlScreens/PartsControlCancelParts.vue'
import PartsControlReturnParts from './orderControlScreens/returnParts/PartsControlReturnParts.vue'
import PartsControlNewPurchaseOrder from './unorderedparts/PartsControlNewManualPurchaseOrder.vue'
import QuoteRoutes from '../route-types'

export default {
  name: 'QuotePartsControl',
  components: {
    QuotePartsControlNotOrdered,
    QuotePartsControlOrdered,
    PartsControlRecieveParts,
    PartsControlCancelParts,
    PartsControlReturnParts,
    PartsControlNewPurchaseOrder
  },
  filters: {
    formatDate: function (value, locale) {
      const options = {
        weekday: 'short',
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      }
      return customFormatDateLocale(value, locale, options)
    }
  },
  props: {
    value: null,
    snapshotDiff: null
  },
  data() {
    return {
      openSubRows: [],
      purchaseOrders: [],
      activePartsCheckRequest: 0,
      partsCheckPrices: [],

      // controlling ordered parts
      // receive, cancel, return
      partsStatusScreen: {
        show: false,
        purchaseOrder: {},
        selectedItems: [],
        orderReceipt: null,
        scrollPosition: 0
      },

      // creating new purchase order
      purchaseOrderScreen: {
        show: false,
        itemsToPurchase: [],
        quote: {},
        scrollPosition: 0
      }
    }
  },
  computed: {
    quotes() {
      return [this.value]
    },
    partsControlEnums() {
      return partsControlEnums
    }
  },
  async mounted() {
    this.getPurchaseOrdersForQuote()
    // this.fetchPartsCheckPrices()
  },
  methods: {
    changeItemStatus(status, purchaseOrder, selectedItems) {
      let el = document.querySelector('.full-quote-page-height')
      this.partsStatusScreen.status = status
      this.partsStatusScreen.show = true
      this.partsStatusScreen.purchaseOrder = purchaseOrder
      this.partsStatusScreen.selectedItems = selectedItems
      this.partsStatusScreen.scrollPosition = el.scrollTop
    },

    handleCreateNewPurchaseOrder(itemsToPurchase) {
      let el = document.querySelector('.full-quote-page-height')
      this.purchaseOrderScreen.show = true
      this.purchaseOrderScreen.itemsToPurchase = itemsToPurchase
      this.purchaseOrderScreen.quote = this.value
      this.purchaseOrderScreen.scrollPosition = el.scrollTop
    },

    //////////// return and edit order functions used for order receipts ////////////
    handleReturnOrder(orderReceipt, purchaseOrder) {
      let el = document.querySelector('.full-quote-page-height')
      this.partsStatusScreen.status = partsControlEnums.return.key
      this.partsStatusScreen.show = true
      this.partsStatusScreen.purchaseOrder = purchaseOrder
      this.partsStatusScreen.orderReceipt = orderReceipt
      this.partsStatusScreen.scrollPosition = el.scrollTop
    },
    handleEditOrder(orderReceipt, purchaseOrder) {
      let el = document.querySelector('.full-quote-page-height')
      this.partsStatusScreen.status = partsControlEnums.receive.key
      this.partsStatusScreen.show = true
      this.partsStatusScreen.purchaseOrder = purchaseOrder
      this.partsStatusScreen.orderReceipt = orderReceipt
      this.partsStatusScreen.scrollPosition = el.scrollTop
    },

    async getPurchaseOrdersForQuote() {
      this.purchaseOrders = await PurchaseOrderService.getPurchaseOrdersForQuote(this.value.quoteId)
    },
    handleOnClose(successMessage = null, reFreshQuote = false) {
      if (successMessage) {
        this.$notification.success(successMessage)
      }

      let scrollPosition

      if (this.purchaseOrderScreen.show) {
        scrollPosition = this.purchaseOrderScreen.scrollPosition
        this.purchaseOrderScreen = {
          show: false,
          itemsToPurchase: [],
          quote: {},
          scrollPosition: 0
        }
      } else if (this.partsStatusScreen.show) {
        scrollPosition = this.partsStatusScreen.scrollPosition
        this.partsStatusScreen = {
          show: false,
          purchaseOrder: {},
          status: '',
          selectedItems: [],
          orderReceipt: null,
          scrollPosition: 0
        }
      }

      // scroll to the position before the status screen was opened
      let el = document.querySelector('.full-quote-page-height')

      this.$nextTick(() => {
        el.scrollBy(0, scrollPosition)
      })

      this.$nextTick(() => {
        this.getPurchaseOrdersForQuote()
      })

      if (reFreshQuote) {
        this.$nextTick(() => {
          this.$emit('getEntity', true)
        })
      }
    },

    async fetchPartsCheckPrices() {
      const pcPrices = await PartsCheckService.getLivePrices(this.value.quoteId)
      this.partsCheckPrices = pcPrices
    },
    async savePartsCheckRequest(request) {
      let selectedLines = []
      for (let line of request.tableData.idxLineItems.lines) {
        if (line.poID != null) continue

        line.priceMatrix.forEach((pmr) => {
          Object.values(pmr).forEach((row) => {
            if (row.price == null) return
            if (row.price.isSelected == '1') {
              selectedLines.push(row)
            }
          })
        })
      }
      console.log(request, selectedLines)
      let packet = {
        draftId: request.tableData.draftInfo.draftID_PK,
        updateQuotePackage: true,
        purchaseItems: [],
        selectItems: []
      }

      for (let item of selectedLines) {
        console.log(item)
        let itemPacket = {
          draftItemId: item.price.draftItemID_PK,
          supplierId: item.supplier.clientID_PK,
          itemTypeId: item.price.itemTypeID_PK,
          modifiedPartNumber: item.price.supplierPartNr,
          priceId: item.price.priceID_PK,
          lineListPrice: item.price.priceListOriginal,
          markupValue: item.price.priceListOriginal,
          markupType: 'uselist',
          lineCostPrice: item.price.priceValue,
          supplierPartNumber: item.price.supplierPartNr,
          quantity: item.price.p_qty,
          unitCostPrice: item.price.priceValue / item.price.p_qty,
          standardPartNumberId: 25
        }
        packet.selectItems.push(itemPacket)
      }

      this.partsCheckPrices = await PartsCheckService.saveLivePrices(this.value.quoteId, packet)
      this.fetchPartsCheckPrices()
    },
    async purchasePartsCheckRequest(request) {
      let packet = {
        draftId: request.tableData.draftInfo.draftID_PK,
        preferredSupplyDate: '24/11/2023',
        note: 'I wanna buy stuff [PETER]',
        updateQuotePackage: true,
        notSelectedReasons: []
      }
      this.partsCheckPrices = await PartsCheckService.purchasePricedParts(this.value.quoteId, packet)
      this.fetchPartsCheckPrices()
    },
    togglePriceSelection(line, supplier) {
      let value = line.priceMatrix[0][supplier.clientID_PK].price.isSelected
      for (var supplierId in line.priceMatrix[0]) {
        if (supplierId == supplier.clientID_PK || line.priceMatrix[0][supplierId].price == null) {
          continue
        }
        line.priceMatrix[0][supplierId].price.isSelected = '0'
      }
      if (line.poID == null) {
        line.priceMatrix[0][supplier.clientID_PK].price.isSelected = value == '1' ? '0' : '1'
      }
    },
    getPriceForCell(line, supplier) {
      // FIX THE 0 NEEDS TO BE SOMETHING MORE
      return line.priceMatrix[0][supplier.clientID_PK]
    },
    getOrderedSupplierList(request) {
      return Object.values(request.tableData.idxSupplierList).sort((a, b) => {
        return a.typeID_PK.localeCompare(b.typeID_PK)
      })
    },
    toggleSubRow(item) {
      this.openSubRows[item.quoteItemId] = !(this.openSubRows[item.quoteItemId] ?? 0)
    },
    goToPriceParts(selectedParts) {
      console.log(selectedParts)

      if (selectedParts.filter((p) => p.deleted === false && p.isNew).length > 0) {
        this.$notification.openNotificationWithType(
          'danger',
          'Price Parts',
          'You have added new part item(s), save quote before you start PartsCheck Price request'
        )
        return false
      }
      if (selectedParts.filter((p) => p.deleted === false).length > 0) {
        this.$emit('setSelectedPartsPartsControl', selectedParts)
        this.$nextTick(() => {
          this.$router.push({
            name: QuoteRoutes.QuotePriceParts.name
          })
        })
      }
    }
  }
}
</script>
<style scoped>
.detail-page-tile {
  background-color: #f9f9f9;
}
</style>

<style lang="scss">
@import '../../../assets/style/themes/default/_variables.scss';

////// styling for unordered and ordered parts
.header {
  border-top: 1px solid #adb5bd;
  div {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
    &.center {
      justify-content: center;
    }
    &.right {
      justify-content: flex-end;
    }

    &.left {
      justify-content: flex-start;
    }
  }
}
.part-row {
  height: 50px !important;
  border: 1px solid #adb5bd;
  border-radius: 5px;
  width: 100%;
  font-size: 0.9em;
  margin-bottom: 5px;
  background: white;
  cursor: pointer;
  &:hover {
    border-color: $primary !important;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
  }

  &.disable-pointer-events {
    pointer-events: none;
    opacity: 0.8;
  }

  div {
    padding: 5px 0px 5px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.center {
      justify-content: center;
    }
    &.right {
      justify-content: flex-end;
    }
    &.left {
      justify-content: flex-start;
    }
  }
}

.item-deleted {
  opacity: 0.5;
  pointer-events: none;
  position: relative;

  .line-through {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    border-top: 1px solid #777777;
    z-index: 1;
  }
}

.sticky-heading {
  position: sticky;
  top: calc(0px);
  z-index: 9;
  background-color: #f5f5f5;
}
////// styling for unordered and ordered parts
</style>