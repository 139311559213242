<template>
  <div class="columns is-multiline p-0 m-0 full-quote-page-height">

    <!---------------------------------------------- parts -------------------------------------------->
    <div class="column is-one-third  br-gray-2">
      <div class="columns is-multiline mt-3">
        <div class="column p-0 m-0 is-full "
          style="height: 30px;">
          <div class="px-3 is-size-5 has-text-weight-bold">
            <span class="is-capitalized">
              Create a new Purchase Order
            </span>
          </div>
        </div>

        <div class="column is-full ">
          <span class="w-100 bb-primary-2 has-text-primary mb-2">
            Parts
          </span>

          <div class="purchase-order-card-header has-text-weight-semibold my-4">
            <div>#</div>
            <div>Description</div>
            <div>Type</div>
            <div>Side</div>

            <div style="align-items: center;">Qty.</div>

            <div style="direction: rtl;">
              Cost (unit)
            </div>
            <div>
              <span>
                Qty
              </span>
            </div>
          </div>

          <div class="purchase-order-card-small"
            v-for="(part, index) in filteredItems"
            :key="index + 'part'">
            <div>{{ index + 1 }} </div>

            <div class="is-flex is-flex-direction-column">
              <span :title="part.itemDesc">{{ part.itemDesc }}</span>
              <span class="is-size-7"
                title="part no">
                {{ part.partNo }}
              </span>
            </div>

            <div>{{ part.itemType }}</div>
            <div>{{ part.side ? part.side : 'N/A' }}</div>
            <div>{{ part.itemType === 'PART' ? part.itemQuantity : 'N/A' }}</div>
            <div style="direction: rtl;">
              {{ $filters.formatCurrency(part.value, $userInfo.locale) }}
            </div>

            <!-- Only show quantities for item types that are parts -->
            <div v-if="part.itemType === 'PART'"
              style="align-items: center;"
              class="is-flex pl-5">
              <div class="item-qty mr-2">
                <vue-numeric class="input has-text-right"
                  :min="1"
                  :disabled="false"
                  :precision="0"
                  @input="checkOrderQty(part)"
                  v-model.number="part.poOrderQty" />
              </div>
              <span>/</span>
              <span>{{ part.itemQuantity - part.quantityOrdered }}</span>
            </div>
            <!-- miscs and sublets -->
            <div v-else>
              N/A
            </div>

            <div style="cursor: pointer;"
              @click="deleteItem(part)">
              <span class="mdi mdi-delete has-text-danger" />
            </div>

          </div>

        </div>

      </div>
    </div>

    <!---------------------------------------------- Invoice & Supplier -------------------------------------------->

    <div class="column is-one-third br-gray-2">
      <div class="columns is-multiline mt-3">

        <div class="column p-0 m-0 is-full "
          style="height: 30px;" />

        <div class="column is-full">
          <span class="w-100 bb-primary-2 has-text-primary mb-2">
            Purchase Order Details
          </span>
        </div>

        <div class="column is-full ">
          <span class=" has-text-weight-semibold">
            Purchase Order No
          </span>
          <div class="is-flex mt-2 ml-2">
            <span>PO. {{ purchaseOrder.purchaseOrderNo }}</span>
          </div>
        </div>

        <div class="column is-full ">
          <span class=" has-text-weight-semibold">
            Total Items
          </span>
          <div class="is-flex mt-2 ml-2">
            <span> {{ filteredItems.length }}</span>
          </div>
        </div>

        <div class="column is-full ">
          <div class="field">
            <label class="label">Vendors</label>
            <div class="field-body">
              <div class="field ">
                <div class="control is-expanded">
                  <multiselect v-if="vendors"
                    v-model="selectedVendor"
                    deselect-label="Can't remove this value"
                    track-by="name"
                    label="name"
                    :show-labels="false"
                    :options="vendors"
                    :options-limit="assetFilter.pageSize"
                    :loading="isVendorLoading"
                    :internal-search="false"
                    :searchable="true"
                    :allow-empty="true"
                    @select="selectVendor"
                    @remove="removeVendor"
                    @search-change="getVendorDropdownFilter"
                    placeholder="Select a vendor">
                    <span class="has-text-danger"
                      slot="noResult">Vendor not found.</span>
                  </multiselect>
                  <!-- <span class="help is-danger"
                    v-if="$v.entity && !$v.entity.vendorId.validGuid">Vendor is required.
                  </span>

                goes in multiselect
                  :class="{'is-danger' : $v.entity.vendorId.$error}"
                -->
                </div>

              </div>
            </div>
          </div>
        </div>

        <!-- <div class="is-column is-full">
          <div class="field-body">
            <div class="field has-addons">
              <div class="control is-expanded">
                <multiselect v-if="contacts"
                  v-model="selectedContact"
                  deselect-label="Can't remove this value"
                  track-by="contactId"
                  label="fullname"
                  :show-labels="false"
                  :options="contacts"
                  :options-limit="assetFilter.pageSize"
                  :internal-search="false"
                  :searchable="true"
                  :allow-empty="true"
                  @select="selectContact"
                  @remove="removeContact"
                  @search-change="onContactSearchChange"
                  @input.native="captureContactInput"
                  placeholder="select a contact">
                  <span class="has-text-danger"
                    slot="noResult">Contact not found.</span>
                </multiselect>
              </div>
              <div class="control">
                <a class="button"
                  :class="contactButtonClass"
                  @click="onEditAddContact()"
                  :disabled="!hasVendorSelected || readOnlyView">
                  <span class="icon">
                    <i class="mdi mdi-24px"
                      :class="contactButtonIconClass" />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div> -->

        <div class="column is-full ">
          <span class=" has-text-weight-semibold">
            Display Prices
          </span>
          <div class="is-flex mt-2 ml-2">
            <span>c43dcece3453e</span>
          </div>
        </div>

      </div>
    </div>
    <!---------------------------------------------- Details -------------------------------------------->
    <div class="column is-one-third">
      <div class="columns is-multiline mt-3">

        <div class="column p-0 m-0 is-full "
          style="height: 30px;">
          <div class="is-flex is-justify-content-end">
            <div class="button mr-2 mt-3 is-danger is-outlined"
              @click="$emit('close')">Cancel</div>
            <div @click="createPo()"
              class="button  mr-2 mt-3 is-success is-outlined"
              :class="{ 'disabled' : isSaveButtonDisabled, 'is-loading' : isPoSaving }">
              Create
            </div>
          </div>
        </div>
        <div class="column is-full ">
          <span class="w-100 bb-primary-2 has-text-primary">
            Details
          </span>
        </div>

        <div class="column is-full ">
          <span class=" has-text-weight-semibold">
            Order By
          </span>
          <div class="is-flex mt-2">
            <div class="field w-100">
              <div class="control is-expanded w-100">
                <bulma-autocomplete placeholder="Orderer"
                  v-model="selectedReciever"
                  :data="recieverList"
                  :keep-first="true"
                  :loading="false"
                  @input="(v) => handleRecieverChange(v)" />
              </div>
            </div>
          </div>
        </div>

        <div class="column is-full ">
          <span class=" has-text-weight-semibold mb-2">
            Order Date
          </span>
          <div class="is-flex mt-2">
            <v-date-picker v-if="!!purchaseOrder"
              v-model="purchaseOrder.purchaseOrderDate"
              :masks="formats"
              :attributes="attrs"
              :locale="$userInfo.locale"
              @input="purchaseOrder.purchaseOrderDate = $filters.fromISOWithCurrentTime($event.toISOString())"
              :timezone="$filters.getIanaTimezone()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type="text"
                      class="input"
                      placeholder="Invoice Date"
                      :value="inputValue"
                      v-on="inputEvents"
                      readonly>
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
            <!-- <span v-else>
              <input class="input is-static"
                :value="$filters.formatDateTimezone(selectedInvoiceDate, $userInfo.locale)"
                readonly>
            </span> -->
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Guid from '../../../../components/Guid.js'
import _cloneDeep from 'lodash/cloneDeep'
import _isEmpty from 'lodash/isEmpty'
import PartsControlService from '../PartsControlService'
import VendorService from '@/views/vendor/VendorService'
import _debounce from 'lodash.debounce'
import { BulmaAutocomplete } from '@/components/BulmaAutocomplete'
import { PurchaseOrderItemModel } from '@/classes/viewmodels'
import { PartTypes } from '@/enums'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import VueNumeric from '@/components/VueNumeric'

export default {
  name: 'PartsControlNewManualPurchaseOrder',
  components: {
    Multiselect,
    BulmaAutocomplete,
    VueNumeric
  },
  props: {
    itemsToPurchase: {
      type: Array,
      default: () => []
    },
    quoteId: null,
    quoteNo: null,
    insurerId: null,
    customerId: null
  },
  data() {
    return {
      creditorInvoiceId: '',
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      innerValue: _cloneDeep(this.purchaseOrder),
      invoiceError: null,
      selectedInvoiceDate: new Date(),
      validateError: false,
      recieverList: [],
      selectedReciever: null,
      selectedVendor: null,
      isVendorLoading: false,
      assetFilter: {
        id: Guid.empty(),
        name: '',
        pageIndex: 1,
        pageSize: 50
      },

      purchaseOrder: {},
      vendors: [],
      items: {
        parts: [],
        sublets: [],
        misc: []
      },
      itemsToRemove: [],
      isPoSaving: false
    }
  },
  computed: {
    filteredItems() {
      // Filter out the items that are marked for removal (delete button)
      return this.itemsToPurchase?.filter((i) => !this.itemsToRemove.includes(i.quoteItemId))
    },
    isSaveButtonDisabled() {
      // Todo: add core here to check if any of the items are selected and quantity is greater than 0

      if (!this.hasVendorSelected || !this.selectedReciever) return true
      return false
    },
    hasVendorSelected() {
      return this.selectedVendor && !_isEmpty(this.selectedVendor)
    },
    vendorButtonIconClass() {
      return {
        'mdi-pencil': this.hasVendorSelected,
        'mdi-plus': !this.hasVendorSelected
      }
    },
    vendorButtonClass() {
      return {
        'is-primary': this.hasVendorSelected,
        'is-success': !this.hasVendorSelected,
        'is-loading': this.vendorButtonLoading
      }
    }
  },
  watch: {
    // vendor ID
    selectedVendor: {
      handler: function (val) {
        if (!!val) this.purchaseOrder.vendorId = val.id
      },
      deep: true
    },
    selectedReciever: {
      handler: function (val) {
        if (val) this.purchaseOrder.orderer = val
      }
    }
  },
  created() {
    // new purchase order
    this.createNewPurchaseOrder()
    // list of orderers for auto complete
    this.getRecieverList()
    // list of vendors for auto complete
    this.getVendorsDropdown()
  },
  methods: {
    checkOrderQty(part) {
      if (part.poOrderQty > part.itemQuantity - part.quantityOrdered) {
        this.$toast.open({
          message: 'Purchase order quantity is greater than the quantity of the item',
          type: 'is-warning',
          position: 'is-bottom',
          queue: false
        })
      }
    },

    // create the purchase order
    async createPo() {
      this.isPoSaving = true

      // change item type to the correct type (purchase order items)
      const purchaseOrder = this.createItems(this.purchaseOrder)
      let result = await PartsControlService.postEntity(purchaseOrder)
      if (result) {
        this.isPoSaving = false
        this.$emit('close', 'Purchase Order Created', true)
      }
    },
    async createNewPurchaseOrder() {
      let purchaseOrder = await PartsControlService.getNewEntity()
      purchaseOrder.quoteId = this.quoteId
      purchaseOrder.quoteNo = this.quoteNo.toString()
      purchaseOrder.insurerId = this.insurerId
      purchaseOrder.customerId = this.customerId
      this.purchaseOrder = purchaseOrder
    },

    // create items for the purchase order, and set the items to the correct type
    createItems(po) {
      let parts = []
      let sublets = []
      let Misc = []
      let items = _cloneDeep(this.filteredItems)
      items.forEach((item) => {
        let newItem = new PurchaseOrderItemModel(
          this.$userInfo.companyId,
          this.purchaseOrder.purchaseOrderId,
          item.quoteItemId,
          item.itemNo,
          item.itemDesc,
          item.partNo,
          item.itemQuantity,
          item.quantityOrdered, ///otherPoOrderedQty,
          item.side
        )

        let mark = item.mark // toString
        const index = Object.values(PartTypes).findIndex((item) => item.value === mark)
        newItem.mark = Object.values(PartTypes)[index]?.description

        newItem.unitPrice = item.value

        newItem.quantity = item.poOrderQty || 1

        // newItem.quantity = quantity // item.itemQuantity - item.otherPoOrderedQty
        newItem.dealerCost = roundAwayFromZero(newItem.quantity * newItem.unitPrice)
        // newItem.otherPoOrderedQty = item.otherPoOrderedQty
        newItem.totalInStock = item.quantityInStock || 0

        if (item.itemType === 'PART') {
          parts.push(newItem)
        } else if (item.itemType === 'Sublet') {
          sublets.push(newItem)
        } else {
          Misc.push(newItem)
        }
      })

      po.parts = parts
      po.sublets = sublets
      po.miscs = Misc

      return po
    },

    deleteItem(item) {
      if (this.filteredItems.length === 1) {
        this.$toast.open({
          message: 'You cannot remove the last item, please cancel the purchase order instead.',
          type: 'is-danger',
          position: 'is-bottom',
          queue: false
        })
      } else {
        this.itemsToRemove.push(item.quoteItemId)
        this.$toast.open({
          message: 'Item removed',
          type: 'is-success',
          position: 'is-bottom',
          queue: false
        })
      }
    },
    selectVendor() {
      return
    },
    removeVendor() {
      return
    },
    async getVendorsDropdown() {
      this.isVendorLoading = true
      this.vendors = await VendorService.getVendorDropdownProto2(this.assetFilter)
      if (this.assetFilter.id !== Guid.empty()) {
        this.selectedVendor = this.vendors.find((c) => c.id === this.innerValue.vendorId)
      }
      this.isVendorLoading = false
      return this.vendors
    },
    getVendorDropdownFilter: _debounce(async function (query) {
      this.isVendorLoading = true
      this.assetFilter.name = query
      this.vendors = await VendorService.getVendorDropdownProto2(this.assetFilter)
      this.isVendorLoading = false
    }, 500),
    async getRecieverList() {
      let recList = await PartsControlService.getOrderersDropdown()
      this.recieverList = recList
      // set the reciever as the user thats currently logged in
      const currentReciever = recList.find((i) => i.toLowerCase() === this.$userInfo?.displayName.toLowerCase())
      this.selectedReciever = currentReciever
      this.purchaseOrder.orderer = currentReciever
    },
    handleRecieverChange(value = null) {
      console.log('value', value)

      this.selectedReciever = value ? value : null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/style/themes/default/_variables.scss';
.bb-primary-2 {
  border-bottom: 2px solid $primary;
}
.br-gray-2 {
  border-right: 1px solid #adb5bd;
}

.w-100 {
  display: flex;
  width: 100%;
}

.purchase-order-card-small {
  border: 1px solid #adb5bd;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
  height: 55px !important;
  align-items: center;

  div {
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    span {
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.purchase-order-card-header {
  border: 1px solid transparent;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;

  div {
    display: flex;
    justify-content: center;
  }
}

.purchase-order-card-small,
.purchase-order-card-header {
  display: grid;
  ////  #, Description, type, side,  Price  Qty
  grid-template-columns: 5% 25% 10% 10% 10% 15% 20% 5%;
}

.date-field {
  border: 1px solid #adb5bd;
  border-radius: 5px;
}

.item-qty {
  min-width: 40px;
  max-width: 40px;
  input {
    width: 100%;
  }
}
</style>
