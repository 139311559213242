import FqApi from '@/services/fq-api'

export default {
  async getEntity(id, hash) {
    const url = !hash ? `/purchaseorders/${id}` : `/purchaseorders/${id}?hash=${hash}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getEntitySummaries(filter) {
    const serialised = this.serialisePurchaseOrderFilter(filter)
    const url = `/purchaseorders/list/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },

  async getAttachments(creditorInvoiceId) {
    return FqApi.get(`/attachments/list?objectId=${creditorInvoiceId}`)
  },
  async getNewEntity() {
    const result = await FqApi.get('/purchaseorders/new')
    return result.data
  },
  putEntity(entity, deepdiff) {
    if (deepdiff) {
      entity.deepDiff = JSON.stringify(deepdiff)
    }
    return FqApi.put(`/purchaseorders/${entity.purchaseOrderId}`, entity)
  },
  async postEntity(entity) {
    return FqApi.post('/purchaseorders', entity)
  },
  async getOrderersDropdown() {
    const url = 'quotes/quoters'
    const result = await FqApi.get(url)
    return result.data
  },
  async getQuoteItemsByQuoteId(quoteId, purchaseOrderId, type) {
    let url = `purchaseorders/quoteitems/${quoteId}/${purchaseOrderId}/${type}`
    const result = await FqApi.get(url)
    return result.data
  },

  async checkInvoiceNoExists(no, vendorId, creditorInvoiceId) {
    let url = `/purchaseorders/exists?no=${no}&vendorId=${vendorId}&creditorInvoiceId=${creditorInvoiceId}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getAssetEmail(assetId) {
    const url = `/purchaseorders/getassetemail/${assetId}`
    const result = await FqApi.get(url)
    return result.data
  }
}
