<template>
  <div class="columns is-multiline p-0 m-0 full-quote-page-height">

    <!---------------------------------------------- parts -------------------------------------------->
    <div class="column is-one-third  br-gray-2">
      <div class="columns is-multiline mt-3">
        <div class="column p-0 m-0 is-full "
          style="height: 30px;">
          <div class="px-3 is-size-5 has-text-weight-bold">
            <span class="is-capitalized">
              {{ orderReceipt ? 'Edit Order Receipt' : 'Recieve Parts' }}
            </span>
          </div>
        </div>

        <div class="column is-full ">
          <span class="w-100 bb-primary-2 has-text-primary mb-2">
            Parts
          </span>

          <div class="purchase-order-card-header has-text-weight-semibold my-4">
            <div>#</div>
            <div>Description</div>
            <div style="direction: rtl;">

              <span>Total:</span>

              {{ $filters.formatCurrency(totalAfterGst, $userInfo.locale ) }}

            </div>
            <div style="direction: rtl;"
              class="pr-4">Qty</div>
          </div>

          <div class="purchase-order-card-small"
            v-for="(part, index) in filteredItems"
            :key="index + 'part'">
            <div>{{ index + 1 }} </div>
            <div>{{ part.description }}</div>
            <div style="direction: rtl;">{{ $filters.formatCurrency(part.unitCost * part.received, $userInfo.locale) }}</div>

            <div style="align-items: center;"
              class="is-flex pl-5">
              <div class="item-qty mr-2">
                <vue-numeric class="input has-text-right"
                  :min="0"
                  :disabled="false"
                  :precision="0"
                  v-model.number="part.received"
                  @input="checkRecQty(part)" />
              </div>
              <span>/</span>
              <span>{{ part.owning }}</span>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!---------------------------------------------- Invoice & Supplier -------------------------------------------->

    <div class="column is-one-third br-gray-2">
      <div class="columns is-multiline mt-3">
        <div class="column p-0 m-0 is-full "
          style="height: 30px;" />
        <div class="column is-full ">
          <span class="w-100 bb-primary-2 has-text-primary">
            Invoice & Supplier
          </span>
        </div>

        <div class="column is-full ">
          <span class=" has-text-weight-semibold">
            Supplier
          </span>
          <div class="is-flex mt-2 ml-2">
            <span>{{ purchaseOrder.vendor.name }}</span>
          </div>
        </div>

        <div class="column is-full has-text-danger">
          <span class=" has-text-weight-semibold">
            ABN
          </span>
          <div class="is-flex mt-2 ml-2">
            <span>{{ purchaseOrder.vendor.abn }}</span>
          </div>
        </div>

        <div class="column is-full ">
          <span class=" has-text-weight-semibold">
            Invoice Number
          </span>
          <div class="field">
            <input class="input"
              :class="{ 'is-danger' : !receiptEntity.invoiceNo}"
              v-model="receiptEntity.invoiceNo"
              type="text"
              placeholder=""
              @input="checkInvoiceNoExists(receiptEntity)">
          </div>
        </div>

        <div class="column is-full">
          <div class="column is-half m-0 p-0">
            <div class="field">
              <label class="label">Total Amt. (Incl GST)</label>
              <div class="control">
                <vue-numeric class="input has-text-right"
                  v-model="receiptEntity.overrideIncGst"
                  type="text"
                  placeholder=""
                  :precision="2"
                  @input.native="updateOverrideTotal(receiptEntity)" />
              </div>
            </div>
          </div>
        </div>

        <div class="column is-full">
          <div class="column is-half m-0 p-0">
            <div class="field is-narrow">
              <label class="label">GST</label>
              <div class="control">
                <vue-numeric v-if="!readOnlyView && receiptEntity.receiptItems && receiptEntity.receiptItems.length > 0"
                  class="input has-text-right"
                  v-model="receiptEntity.overrideGst"
                  type="text"
                  placeholder=""
                  :precision="2"
                  @blur="checkOverrideGst(receiptEntity)" />
                <span v-else>{{ receiptEntity.overrideGst | formatCurrency($userInfo.locale) }}</span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!---------------------------------------------- Details -------------------------------------------->
    <div class="column is-one-third">
      <div class="columns is-multiline mt-3">
        <div class="column p-0 m-0 is-full "
          style="height: 30px;">
          <div class="is-flex is-justify-content-end">
            <div class="button mr-2 mt-3"
              @click="$emit('close')">Cancel</div>
            <div @click="savePo(false)"
              class="button  mr-2 mt-3"
              :class="{ 'disabled' : isSaveButtonDisabled }">
              Save
            </div>
          </div>
        </div>
        <div class="column is-full ">
          <span class="w-100 bb-primary-2 has-text-primary">
            Details
          </span>
        </div>

        <div class="column is-full ">
          <span class=" has-text-weight-semibold">
            Purchase Order Number
          </span>
          <div class="is-flex mt-2 ml-2">
            <span>PO. {{ purchaseOrder.purchaseOrderNo }}</span>
          </div>
        </div>

        <div class="column is-full ">
          <span class=" has-text-weight-semibold">
            Received By
          </span>
          <div class="is-flex mt-2">
            <div class="field w-100">
              <div class="control is-expanded w-100">
                <bulma-autocomplete placeholder="R"
                  v-model="selectedReciever"
                  :data="recieverList"
                  :keep-first="true"
                  :loading="false"
                  @input="(v) => handleRecieverChange(v)" />
              </div>
            </div>
          </div>
        </div>

        <div class="column is-full ">
          <div class="field is-narrow">
            <label class="label">Date</label>
            <v-date-picker v-if="receiptEntity.receiptItems && receiptEntity.receiptItems.length > 0"
              v-model="selectedInvoiceDate"
              :masks="formats"
              :attributes="attrs"
              :locale="$userInfo.locale"
              @input="selectedInvoiceDate = $filters.fromISOWithCurrentTime($event.toISOString())"
              :timezone="$filters.getIanaTimezone()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type="text"
                      class="input"
                      placeholder="Invoice Date"
                      :value="inputValue"
                      v-on="inputEvents"
                      readonly>
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
            <span v-else>
              <input class="input is-static"
                :value="$filters.formatDateTimezone(selectedInvoiceDate, $userInfo.locale)"
                readonly>
            </span>
          </div>
        </div>

        <div class="column is-full">
          <div class="field">
            <div class="file is-pulled-left is-primary">

              <div class="button is-primary"
                @click="handleAttachmentButton">
                <i class="mdi mdi-upload mdi-20px mr-2" />
                <span>Add / View Attachment</span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// import Multiselect from 'vue-multiselect'
import Guid from '../../../../components/Guid.js'
import partsControlEnums from '../parts-control-enums.js'
import { PurchaseOrderReceiptModel, PurchaseOrderReceiptItemModel } from '@/classes/viewmodels'
import _cloneDeep from 'lodash/cloneDeep'
import VueNumeric from '@/components/VueNumeric'
import { roundAwayFromZero } from '../../../../utils/AccountingFunctions'
import { InvoicePrefixShortFormTypes, EventHubTypes } from '@/enums'
import PartsControlService from '../PartsControlService'
import DeepDiff from 'deep-diff'
import { BulmaAutocomplete } from '@/components/BulmaAutocomplete'

const title = 'Order Receipt'

export default {
  name: 'PartsControlStatusScreen',
  components: {
    // Multiselect,
    VueNumeric,
    BulmaAutocomplete
  },
  props: {
    selectedItems: {
      type: Array,
      default: () => []
    },
    purchaseOrder: {
      type: Object,
      default: () => {}
    },
    orderReceipt: {
      type: Object,
      default: null
    },
    readOnlyView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      creditorInvoiceId: '',
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      innerValue: _cloneDeep(this.purchaseOrder),
      invoiceError: null,
      selectedInvoiceDate: new Date(),
      validateError: false,
      recieverList: [],
      selectedReciever: null
    }
  },
  computed: {
    isSaveButtonDisabled() {
      return false
      return !this.isValidInvoice || !this.isValidQtyAmt
    },
    partsControlEnums() {
      return partsControlEnums
    },
    receiptEntity() {
      return this.innerValue.orderReceipts.find((receipt) => receipt.creditorInvoiceId === this.creditorInvoiceId) || {}
    },
    activeItems() {
      return this.receiptEntity.receiptItems
    },
    filteredItems() {
      return this.innerValue.orderReceipts.find((receipt) => receipt.creditorInvoiceId === this.creditorInvoiceId)?.receiptItems || []
    },
    poItems() {
      return this.innerValue.parts.concat(this.innerValue.miscs, this.innerValue.sublets, this.innerValue.otherPurchases)
    },
    totalGst() {
      if (!this.activeItems) return 0
      const gstRate = this.purchaseOrder.gstRate
      return roundAwayFromZero(
        this.activeItems
          .filter((i) => i.received !== 0)
          .reduce(function (total, item) {
            return total + item.unitCost * item.received * (gstRate / 100)
          }, 0),
        2
      )
    },
    totalAfterGst() {
      if (!this.activeItems) return 0

      return roundAwayFromZero(
        this.activeItems
          .filter((i) => i.received !== 0)
          .reduce(function (total, item) {
            return total + item.unitCost * item.received
          }, 0),
        2
      )
    }
  },
  watch: {
    purchaseOrder: {
      handler: function (val) {
        this.saveSnapshot = _cloneDeep(val)
      },
      deep: true
    },
    totalGst(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.receiptEntity.gstAmount = newVal
        this.receiptEntity.overrideGst = newVal
      }
    },
    totalAfterGst(newVal, oldVal) {
      if (newVal) {
        console.log(newVal, oldVal)

        this.receiptEntity.totalAmount = newVal
      }
    },
    overrideTotalAfterGst(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.receiptEntity.overrideIncGst = newVal
      }
    },
    overrideTotalBeforeGst(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.receiptEntity.overrideExGst = newVal
      }
    },
    totalAfterGst(newVal, oldVal) {
      if (newVal) this.receiptEntity.totalAmount = newVal
      if (!this.receiptEntity.overrideIncGst || this.receiptEntity.overrideIncGst < newVal) {
        this.receiptEntity.overrideIncGst = newVal
      }
    }
  },
  created() {
    this.getRecieverList()
    // new order receipt
    if (!this.orderReceipt) {
      this.createNewOrderReceipt()
    } else {
      // edit order receipt
      this.creditorInvoiceId = this.orderReceipt.creditorInvoiceId
    }
  },
  methods: {
    createNewOrderReceipt() {
      let creditorInvoiceId = Guid.newGuid()
      this.creditorInvoiceId = creditorInvoiceId

      const newReceipt = new PurchaseOrderReceiptModel(
        this.innerValue.companyId,
        this.innerValue.purchaseOrderId,
        creditorInvoiceId,
        this.innerValue.vendorId,
        this.innerValue.vendor.abn,
        this.innerValue.vendor.name,
        this.innerValue.quoteId,
        this.innerValue.vendorGlCode,
        this.innerValue.gstRate
      )
      newReceipt.invoiceDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()) //new Date().toISOString()
      this.innerValue.orderReceipts.push(newReceipt)

      let lastIndex = this.innerValue.orderReceipts.length - 1
      let receiptItems = this.selectedItems

      if (receiptItems.length > 0) {
        for (let i = 0; i < receiptItems.length; i++) {
          if (receiptItems[i].quantity > receiptItems[i].receivedQty - receiptItems[i].returnQty) {
            const newItem = new PurchaseOrderReceiptItemModel(
              this.innerValue.companyId,
              creditorInvoiceId,
              this.innerValue.vendorId,
              receiptItems[i].purchaseOrderItemId
            )
            const date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
            newItem.quote2ItemId = receiptItems[i].quoteItemId !== null ? receiptItems[i].quoteItemId : Guid.empty()
            newItem.receivedDate = date.toISOString().split('.')[0] + 'Z'
            newItem.account = this.innerValue.vendorGlCode
            newItem.description = receiptItems[i].description
            newItem.itemNo = receiptItems[i].itemNo
            newItem.side = receiptItems[i].side
            newItem.orderQty = receiptItems[i].quantity
            newItem.owning = receiptItems[i].quantity - receiptItems[i].receivedQty + receiptItems[i].returnQty
            newItem.totalReceivedQty = receiptItems[i].receivedQty
            newItem.partNo = receiptItems[i].partNo
            newItem.unitCost = receiptItems[i].qtePrice
            newItem.dealerCost =
              (receiptItems[i].quantity - (receiptItems[i].receivedQty - receiptItems[i].returnQty)) * (receiptItems[i].dealerCost / receiptItems[i].quantity)
            newItem.gstAmount = newItem.dealerCost * (this.innerValue.gstRate / 100)
            newItem.totalAmount = newItem.dealerCost + newItem.gstAmount

            this.innerValue.orderReceipts[lastIndex].receiptItems.push(newItem)
          }
        }
      }
    },
    handleAttachmentButton() {
      this.$eventHub.$emit(EventHubTypes.subSidePanelChanged, 'QuoteDocuments')
    },
    checkRecQty(item, title = 'Recieve Quantity') {
      console.log(item)

      if (item.received > item.owning) {
        item.received = item.owning
        this.$toast.open({
          message: 'Received Qty cannot be more than Ordered Qty & returned Qty.',
          type: 'is-danger ',
          position: 'is-bottom',
          queue: false
        })
      }

      //calculate totalReceivedQty for all receipt order for the same PO item
      var totalRec = 0
      this.innerValue.orderReceipts.forEach(function (receipt) {
        let found = receipt.receiptItems.some((i) => i.purchaseOrderItemId === item.purchaseOrderItemId)
        if (found) {
          let recQty = receipt.receiptItems.filter((i) => i.purchaseOrderItemId === item.purchaseOrderItemId)[0].received
          totalRec = totalRec + recQty
        }
      })

      // update totalReceivedQty in all order receipt for the same PO item
      this.innerValue.orderReceipts.forEach(function (receipt) {
        let found = receipt.receiptItems.some((i) => i.purchaseOrderItemId === item.purchaseOrderItemId)
        if (found) {
          let receiptItem = receipt.receiptItems.filter((i) => i.purchaseOrderItemId === item.purchaseOrderItemId)[0]
          receiptItem.totalReceivedQty = totalRec
        }
      })

      // update totalReceivedQty in AT_PurchaseOrderItem table
      var poItem = this.poItems.filter((i) => i.purchaseOrderItemId === item.purchaseOrderItemId && !i.deleted)[0]
      poItem.receivedQty = totalRec
      this.$nextTick(() => {
        this.checkQtyAmount(this.receiptEntity)
      })
    },
    updateOverrideTotal(receipt) {
      // receipt.overrideGst = this.innerValue.gstDivisor == 0 ? 0 : roundAwayFromZero(receipt.overrideIncGst / this.innerValue.gstDivisor, 2)
      receipt.overrideExGst = receipt.overrideIncGst - receipt.overrideGst
      var dUpp = roundAwayFromZero(this.overrideTotalAfterGst + 0.05, 2)
      var dLow = roundAwayFromZero(this.overrideTotalAfterGst - 0.05, 2)
      if (receipt.overrideIncGst < dLow || (receipt.overrideIncGst > dUpp && receipt.overrideIncGst != 0)) {
        receipt.overrideIncGst = roundAwayFromZero(this.totalAfterGst, 2)
        this.$notification.openNotificationWithType('danger', title, 'Total Invoice amount should be between ' + dLow + ' and ' + dUpp)
        return false
      }
      this.checkQtyAmount(receipt)
    },
    checkQtyAmount(receipt) {
      if (this.totalReceiptQty === 0 || (receipt.totalAmount === 0 && receipt.overrideIncGst === 0)) {
        this.isValidQtyAmt = false
      } else {
        this.isValidQtyAmt = true
      }
    },
    checkOverrideGst(receipt) {
      if (receipt.overrideGst > 0) {
        var dUpp = this.innerValue.gstDivisor == 0 ? 0 : roundAwayFromZero(receipt.overrideIncGst / this.innerValue.gstDivisor + 0.1, 2)
        var dLow = this.innerValue.gstDivisor == 0 ? 0 : roundAwayFromZero(receipt.overrideIncGst / this.innerValue.gstDivisor - 0.1, 2)
        if (receipt.overrideGst < dLow || (receipt.overrideGst > dUpp && receipt.overrideGst != 0)) {
          receipt.overrideGst = this.innerValue.gstDivisor == 0 ? 0 : roundAwayFromZero(receipt.overrideIncGst / this.innerValue.gstDivisor, 2)
          // this.$notification.error('GST amount should be zero or between ' + dLow + ' and ' + dUpp)
          this.$toast.open({
            message: 'GST amount should be zero or between ' + dLow + ' and ' + dUpp,
            type: 'is-danger ',
            position: 'is-bottom',
            queue: false
          })

          return false
        }
      }
    },
    async checkInvoiceNoExists(receipt) {
      if (receipt.invoiceNo) {
        let found = receipt.invoiceNo.toUpperCase().includes(InvoicePrefixShortFormTypes.RFC)
        if (found) {
          this.invoiceError = 'Cannot use ' + InvoicePrefixShortFormTypes.RFC + ' as part of invoice number.'
          this.isValidInvoice = false
        } else {
          let invoiceId = await PartsControlService.checkInvoiceNoExists(receipt.invoiceNo, this.innerValue.vendorId, receipt.creditorInvoiceId)

          if (invoiceId === Guid.empty()) {
            this.isValidInvoice = true
            this.invoiceError = null
          } else {
            this.isValidInvoice = false
            this.invoiceError = 'Invoice Number already in used.'
          }
        }
      } else {
        this.isValidInvoice = false
        this.invoiceError = 'Invoice Number is required.'
      }
    },

    sort(name, order) {
      this.receiptEntity.receiptItems = _orderBy(this.activeItems, name, order)
    },
    async savePo(isDelete) {
      const difference = DeepDiff.diff(this.purchaseOrder, this.innerValue)

      if (!difference && this.validateError && !isDelete) {
        this.$notification.openNotificationWithType('danger', title, 'Validation errors. Please fix before saving')
        return false
      }

      try {
        this.$showSpinner('Saving...')
        let response

        response = await PartsControlService.putEntity(_cloneDeep(this.innerValue), difference)

        this.$hideSpinner()
        //   if (this.isSaveContinue) {
        //     this.$notification.openNotificationWithType('success', title, isDelete ? `${title} deleted` : 'Save successful')
        //   } else if (response && response.status === HttpStatus.NO_CONTENT) {
        //     await this.getEntity(true)
        //     this.$notification.openNotificationWithType('success', title, isDelete ? `${title} deleted` : 'Save successful')
        //   } else if (response && response.status === HttpStatus.CREATED) {
        //     await this.$router.replace({
        //       name: this.$route.name,
        //       params: { purchaseOrderId: response.data.purchaseOrderId }
        //     })
        //     await this.getEntity(true)
        //     this.$notification.openNotificationWithType('success', title, `New ${title} added`)
        //   }

        //   if (!this.isSaveContinue) {
        //     this.$eventHub.$emit(EventHubTypes.EntitySaved)
        //   }
        //   return true
        // } catch (e) {
        //   console.log('error', e)

        //   return false
        // }
        console.log(response)

        this.$emit('close', 'You have successfully received the parts.')
      } catch (e) {
        if (e.response.request && e.response.request.status === HttpStatus.CONFLICT) {
          this.isSaveConflictModalActive = true
        } else {
          this.$notification.openMessageXhrError(title, e)
        }
      }
    },
    async getRecieverList() {
      let recList = await PartsControlService.getOrderersDropdown()
      this.recieverList = recList
      const currentReciever = recList.find((i) => i.toLowerCase() === this.$userInfo?.displayName.toLowerCase())
      this.selectedReciever = currentReciever
    },
    handleRecieverChange(value) {
      this.selectedReciever = value
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/style/themes/default/_variables.scss';
.bb-primary-2 {
  border-bottom: 2px solid $primary;
}
.br-gray-2 {
  border-right: 1px solid #adb5bd;
}

.w-100 {
  display: flex;
  width: 100%;
}

.purchase-order-card-small {
  border: 1px solid #adb5bd;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
  height: 45px !important;
  align-items: center;
}

.purchase-order-card-header {
  border: 1px solid transparent;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

.purchase-order-card-small,
.purchase-order-card-header {
  display: grid;
  ////  # Description  Price  Qty
  grid-template-columns: 5% 45% 30% 20%;
}

.date-field {
  border: 1px solid #adb5bd;
  border-radius: 5px;
}

.item-qty {
  min-width: 40px;
  max-width: 40px;
  input {
    width: 100%;
  }
}
</style>
