<template>
  <quote-parts-control-section>
    <template slot="heading">
      <span class="has-text-weight-bold">
        Not Ordered total
      </span>
      <span class="ml-2 has-text-weight-bold">
        Cost: {{ $filters.formatCurrency(addTotal(unorderedParts, 'buyPrice') , $userInfo.locale) }}
      </span>
      <span class="ml-2 has-text-weight-bold">
        Quote: {{ $filters.formatCurrency(addTotal(unorderedParts, 'value') , $userInfo.locale) }}
      </span>
    </template>
    <template slot="heading_buttons">
      <div class="button ml-2 is-success"
        @click="$emit('goToPriceParts', selectedPartsArray)"
        :class="{'disabled is-outlined' : pricePartsDisabled}">
        Price Parts
      </div>
      <div class="button ml-2 is-primary"
        :class="{'disabled is-outlined': !selectedRows?.length}"
        @click="handleNewPurchaseOrder">
        Manual Purchase Order
      </div>
    </template>

    <template slot="body"
      v-if="unorderedParts.length > 0">

      <div class="unordered-parts header">
        <div>#</div>

        <div class="center"
          :title="snapshotDiff ? 'Please Save the Quote before making changes to the parts.' : 'Select Part'">
          <div class="field has-checkradio"
            :class="{'disabled' : !!snapshotDiff}"
            style="margin-top: -20px; margin-left: 10px;">
            <div class="control">
              <input class="is-checkradio is-primary"
                :class="{ 'has-background-color': areAllChecked }"
                :id="`checkbox-all-unordered-parts`"
                @click="titleCheckboxChanged"
                :name="`checkbox-all-unordered-parts`"
                :checked="areAllChecked"
                type="checkbox">
              <label :for="`checkbox-all-unordered-parts`" />
            </div>
          </div>
        </div>

        <!-- <div>Req.</div> -->
        <div class="left">Description</div>
        <div>Part No</div>
        <div>Item Type</div>
        <div>Authorised</div>
        <div>Supp#</div>
        <div>Qty</div>
        <div>Supplier</div>
        <div>Cost Price</div>
        <div>Sell Price</div>
        <div>Status</div>
      </div>

      <div>
        <div v-for="(item, index) in unorderedParts"
          @click="handleSelectItem(item)"
          :key="item.quoteItemId">

          <div class="is-size-7 px-2 py-1 has-text-weight-bold"
            v-if="index == 0 || item.itemType !== unorderedParts[index-1]?.itemType">
            {{ typeFullName?.[item.itemType] }}
          </div>

          <div class="unordered-parts part-row">
            <div>
              <span>{{ index + 1 }}</span>
            </div>
            <div class="center"
              :title="snapshotDiff ? 'Please Save the Quote before making changes to the parts.' : 'Select Part'">
              <div class="field has-checkradio"
                :class="{'disabled' : !!snapshotDiff}"
                style="margin-top: -20px; margin-left: 10px;">
                <div class="control">
                  <input class="is-checkradio is-primary"
                    :class="{ 'has-background-color': selectedRows.includes(item.quoteItemId) }"
                    :id="`child-checkbox-unordered-parts` + index"
                    @click="handleSelectItem(item)"
                    :name="`child-checkbox-unordered-parts` + index"
                    :checked="selectedRows.includes(item.quoteItemId)"
                    type="checkbox">
                  <label :for="`child-checkbox-unordered-parts` + index" />
                </div>
              </div>
            </div>

            <!-- <div class="has-text-centered">xxxx</div> -->
            <div class="left">{{ item.itemDesc }}</div>
            <div>{{ item.partNo }}</div>
            <div class="has-text-centered">{{ PartTypes?.[item.mark] || '-' }}</div>
            <div class="has-text-centered">Auth</div>
            <div class="has-text-centered">Sup</div>
            <div class="has-text-centered">{{ item.itemType === "PART" ? item.itemQuantity : 'N/A' }}</div>
            <div class="has-text-centered">{{ item.itemType === "PART" ? item.quantityOrdered : 'N/A' }}</div>
            <div class="has-text-centered">Supplier</div>
            <div class="has-text-right">{{ $filters.formatCurrency(item.buyPrice , $userInfo.locale) }}</div>
            <div class="has-text-right">{{ $filters.formatCurrency(item.value , $userInfo.locale) }}</div>
            <div class="has-text-centered"
              :title="getStatus(item)?.title">
              <span :class="[getStatus(item)?.classStatus]" />

            </div>
          </div>
        </div>
      </div>

    </template>
    <template slot="footer">
      <div class="button mr-2 is-primary is-outlined">
        <div class="icon">
          <i class="mdi mdi-plus mdi-24px" />
        </div>
        <span>Add Part</span>
      </div>
      <div class="button mr-2 is-primary is-outlined">
        <div class="icon">
          <i class="mdi mdi-plus mdi-24px" />
        </div>
        <span>Add Sublet</span>
      </div>
      <div class="button mr-2 is-primary is-outlined">
        <div class="icon">
          <i class="mdi mdi-plus mdi-24px" />
        </div>
        <span>Add Misc</span>
      </div>
    </template>

  </quote-parts-control-section>
</template>

<script>
import QuotePartsControlSection from '../QuotePartsControlSection.vue'
import { customFormatDateLocale } from '@/utils/DateFunctions'
import _cloneDeep from 'lodash/cloneDeep'
import { PartTypes } from '../../../../enums'

const typeFullName = {
  PART: 'Parts',
  SUBL: 'Sublets',
  MISC: 'Miscellaneous'
}

export default {
  name: 'QuotePartsControlNotOrdered',
  components: { QuotePartsControlSection },
  filters: {
    formatDate: function (value, locale) {
      const options = {
        weekday: 'short',
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      }
      return customFormatDateLocale(value, locale, options)
    }
  },
  props: {
    value: null,
    function: Function,
    snapshotDiff: null
  },
  data() {
    return {
      openSubRows: [],
      purchaseOrders: [],
      activePartsCheckRequest: 0,
      partsCheckPrices: [],
      selectedRows: []
    }
  },
  computed: {
    typeFullName() {
      return typeFullName
    },
    PartTypes() {
      let obj = {}
      Object.values(PartTypes).map((item, index) => {
        obj = { ...obj, [item.value]: item.description }
      })
      return obj
    },
    quotes() {
      return [this.value]
    },
    unorderedParts() {
      let lines = []
      for (let quote of this.quotes) {
        lines = [
          ...lines,
          ...quote.parts.filter((r) => r.quantityOrdered < r.itemQuantity), /// quantity ordered is less than quanti
          ...quote.sublets.filter((r) => !r.quantityOrdered), // sublets and miscs are dont have quantity figure
          ...quote.miscs.filter((r) => !r.quantityOrdered)
        ]
      }
      return lines
    },
    areAllChecked() {
      return this.unorderedParts?.length === this.selectedRows?.length && !!this.unorderedParts?.length
    },
    pricePartsDisabled() {
      // if there are no selected rows or if all selected rows are not parts
      return (
        !this.unorderedParts?.filter((item) => this.selectedRows.includes(item.quoteItemId)).every((item) => item.itemType === 'PART') ||
        !this.selectedRows?.length
      )
    },
    selectedPartsArray() {
      return this.unorderedParts.filter((item) => this.selectedRows.includes(item.quoteItemId))
    }
  },
  methods: {
    getStatus(part) {
      let classStatus = 'mdi mdi-22px mdi-checkbox-marked-outline '
      let title = ''
      if (!part.partStatus) {
        classStatus += 'has-text-grey'
        title = 'Not Requested'
      } else if (part.partStatus === 'Exp') {
        classStatus += 'has-text-warning'
        title = 'Requested'
      } else if (part.partStatus === 'Imp') {
        classStatus += 'has-text-success'
        title = 'Priced'
      }
      return { classStatus, title }
    },
    addTotal(arr, key) {
      return arr.reduce((a, b) => a + (b[key] || 0), 0)
    },
    titleCheckboxChanged() {
      if (!!this.snapshotDiff) {
        this.$toast.open({
          message: 'Please Save the Quote before making changes to the parts.',
          type: 'is-danger',
          position: 'is-bottom',
          duration: 5000,
          queue: false
        })
      } else {
        if (this.areAllChecked) {
          this.selectedRows = []
        } else {
          this.selectedRows = this.unorderedParts.map((item) => item.quoteItemId)
        }
      }
    },
    handleSelectItem(item) {
      if (!!this.snapshotDiff) {
        this.$toast.open({
          message: 'Please Save the Quote before making changes to the parts.',
          type: 'is-danger',
          position: 'is-bottom',
          duration: 5000,
          queue: false
        })
      } else {
        if (this.selectedRows.includes(item.quoteItemId)) {
          this.selectedRows = this.selectedRows.filter((row) => row !== item.quoteItemId)
        } else {
          this.selectedRows.push(item.quoteItemId)
        }
      }
    },
    handleNewPurchaseOrder() {
      const items = this.unorderedParts.filter((item) => this.selectedRows.includes(item.quoteItemId))
      this.$emit('newPurchaseOrder', _cloneDeep(items))
    }
  }
}
</script>

<style lang="scss" scoped>
.unordered-parts {
  display: grid;
  // headings : #, checkbox, Req., Description, Part No, Part Type, Authorised, Supp#, Qty, Supplier, Cost Price, Sell Price, Status
  grid-template-columns: 2% 5% 28% 10% 10% 5% 5% 5% 5% 10% 5% 5% 5%;
}
</style>