<template>
  <div class="columns is-multiline p-0 m-0 full-quote-page-height">

    <!---------------------------------------------- parts -------------------------------------------->
    <div class="column is-one-third  br-gray-2">
      <div class="columns is-multiline mt-3">
        <div class="column p-0 m-0 is-full "
          style="height: 30px;">
          <div class="px-3 is-size-5 has-text-weight-bold">
            <span class="is-capitalized">Cancel Parts</span>

          </div>
        </div>

        <div class="column is-full ">
          <span class="w-100 bb-primary-2 has-text-primary mb-2">
            Parts
          </span>

          <div class="purchase-order-card-header has-text-weight-semibold my-4">
            <div>#</div>
            <div>Description</div>
            <div style="direction: rtl;">
              {{ $filters.formatCurrency(totalAfterGst, $userInfo.locale ) }}
            </div>
            <div class="pl-5">Qty</div>
          </div>

          <div class="purchase-order-card-small"
            v-for="(part, index) in selectedItems"
            :key="index + 'part'">
            <div>{{ index + 1 }} </div>
            <div>{{ part.description }}</div>
            <div style="direction: rtl;">{{ $filters.formatCurrency(part.qtePrice * part.quantity, $userInfo.locale) }}</div>

            <div style="align-items: center;"
              class="is-flex pl-5">
              <span>{{ part.quantity }}</span>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!---------------------------------------------- Invoice & Supplier -------------------------------------------->

    <div class="column is-one-third br-gray-2">
      <div class="columns is-multiline mt-3">
        <div class="column p-0 m-0 is-full "
          style="height: 30px;" />
        <div class="column is-full ">
          <span class="w-100 bb-primary-2 has-text-primary">
            Vehicle & Supplier
          </span>
        </div>

        <div class="column is-full ">
          <span class=" has-text-weight-semibold">
            Supplier
          </span>
          <div class="is-flex mt-2 ml-2">
            <span>{{ purchaseOrder.vendor.name }}</span>
          </div>
        </div>

        <div class="column is-full ">
          <span class=" has-text-weight-semibold">
            Supplier
          </span>
          <div class="is-flex mt-2 ml-2">
            <span>{{ purchaseOrder.vendor.name }}</span>
          </div>
        </div>

        <div class="column is-full has-text-danger">
          <span class=" has-text-weight-semibold">
            ABN
          </span>
          <div class="is-flex mt-2 ml-2">
            <span>{{ purchaseOrder.vendor.abn }}</span>
          </div>
        </div>

      </div>
    </div>

    <!---------------------------------------------- Details -------------------------------------------->
    <div class="column is-one-third">
      <div class="columns is-multiline mt-3">
        <div class="column p-0 m-0 is-full "
          style="height: 30px;">
          <div class="is-flex is-justify-content-end">
            <div class="button mr-2 mt-3"
              @click="$emit('close')">Cancel</div>
            <div @click="savePo(false)"
              class="button  mr-2 mt-3">Save</div>
          </div>
        </div>
        <div class="column is-full ">
          <span class="w-100 bb-primary-2 has-text-primary">
            Details
          </span>
        </div>

        <div class="column is-full ">
          <span class=" has-text-weight-semibold">
            Purchase Order Number
          </span>
          <div class="is-flex mt-2 ml-2">
            <span>PO. {{ purchaseOrder.purchaseOrderNo }}</span>
          </div>
        </div>

        <div class="column is-full ">
          <span class=" has-text-weight-semibold">
            Cancelled By
          </span>
          <div class="is-flex mt-2">
            <div class="field w-100">
              <div class="control is-expanded w-100">
                <bulma-autocomplete placeholder="R"
                  v-model="selectedReciever"
                  :data="recieverList"
                  :keep-first="true"
                  :loading="false"
                  @input="(v) => handleRecieverChange(v)" />
              </div>
            </div>
          </div>
        </div>

        <div class="column is-full">
          <span class=" has-text-weight-semibold">
            Reason
          </span>
          <div class="is-flex mt-2">
            <div class="field w-100">
              <div class="control is-expanded w-100">
                <multiselect track-by="message"
                  label="message"
                  placeholder=""
                  :options="[
                    { message: 'Hello' },
                    { message: 'World' }
                  ]"
                  :loading="false"
                  :searchable="true"
                  :allow-empty="true"
                  @select="handleSelect" />
              </div>
            </div>
          </div>
        </div>

        <div class="column is-full">
          <span class=" has-text-weight-semibold">
            Comments
          </span>
          <div class="is-flex mt-2 w-100">
            <div class="field mb-0 w-100">
              <div class="control w-100">
                <textarea class="textarea"
                  rows="6"
                  maxlength="2000"
                  placeholder="Reason for cancelling, etc." />
              </div>
            </div>
          </div>
        </div>

        <div class="column is-full ">
          <div class="field is-narrow">
            <label class="label">Date</label>
            <v-date-picker v-if="receiptEntity.receiptItems && receiptEntity.receiptItems.length > 0"
              v-model="selectedInvoiceDate"
              :masks="formats"
              :attributes="attrs"
              :locale="$userInfo.locale"
              @input="selectedInvoiceDate = $filters.fromISOWithCurrentTime($event.toISOString())"
              :timezone="$filters.getIanaTimezone()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type="text"
                      class="input"
                      placeholder="Invoice Date"
                      :value="inputValue"
                      v-on="inputEvents"
                      readonly>
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
            <span v-else>
              <input class="input is-static"
                :value="$filters.formatDateTimezone(selectedInvoiceDate, $userInfo.locale)"
                readonly>
            </span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
// import Guid from '../../../../components/Guid.js'
import partsControlEnums from '../parts-control-enums.js'
// import { PurchaseOrderReceiptModel, PurchaseOrderReceiptItemModel } from '@/classes/viewmodels'
import _cloneDeep from 'lodash/cloneDeep'
import { roundAwayFromZero } from '../../../../utils/AccountingFunctions'
import PartsControlService from '../PartsControlService'
import DeepDiff from 'deep-diff'
import { BulmaAutocomplete } from '@/components/BulmaAutocomplete'

export default {
  name: 'PartsControlCancelParts',
  components: {
    Multiselect,
    BulmaAutocomplete
  },
  props: {
    status: {
      type: String,
      default: ''
    },
    selectedItems: {
      type: Array,
      default: () => []
    },
    purchaseOrder: {
      type: Object,
      default: () => {}
    },
    readOnlyView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      creditorInvoiceId: '',
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      innerValue: _cloneDeep(this.purchaseOrder),
      invoiceError: null,
      selectedInvoiceDate: new Date(),
      validateError: false,
      recieverList: [],
      selectedReciever: null
    }
  },
  computed: {
    partsControlEnums() {
      return partsControlEnums
    },
    receiptEntity() {
      return this.innerValue.orderReceipts.find((receipt) => receipt.creditorInvoiceId === this.creditorInvoiceId) || {}
    },
    activeItems() {
      return this.receiptEntity.receiptItems
    },
    filteredItems() {
      return this.innerValue.orderReceipts.find((receipt) => receipt.creditorInvoiceId === this.creditorInvoiceId)?.receiptItems || []
    },
    poItems() {
      return this.innerValue.parts.concat(this.innerValue.miscs, this.innerValue.sublets, this.innerValue.otherPurchases)
    },
    totalGst() {
      if (!this.activeItems) return 0
      const gstRate = this.purchaseOrder.gstRate
      return roundAwayFromZero(
        this.activeItems
          .filter((i) => i.received !== 0)
          .reduce(function (total, item) {
            return total + item.unitCost * item.received * (gstRate / 100)
          }, 0),
        2
      )
    },
    totalAfterGst() {
      if (!this.activeItems) return 0

      return roundAwayFromZero(
        this.activeItems
          .filter((i) => i.received !== 0)
          .reduce(function (total, item) {
            return total + item.unitCost * item.received
          }, 0),
        2
      )
    }
  },
  watch: {
    purchaseOrder: {
      handler: function (val) {
        this.saveSnapshot = _cloneDeep(val)
      },
      deep: true
    },
    totalGst(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.receiptEntity.gstAmount = newVal
        this.receiptEntity.overrideGst = newVal
      }
    },
    totalAfterGst(newVal, oldVal) {
      if (newVal) {
        console.log(newVal, oldVal)

        this.receiptEntity.totalAmount = newVal
      }
    },
    overrideTotalAfterGst(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.receiptEntity.overrideIncGst = newVal
      }
    },
    overrideTotalBeforeGst(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.receiptEntity.overrideExGst = newVal
      }
    },
    totalAfterGst(newVal, oldVal) {
      if (newVal) this.receiptEntity.totalAmount = newVal
      if (!this.receiptEntity.overrideIncGst || this.receiptEntity.overrideIncGst < newVal) {
        this.receiptEntity.overrideIncGst = newVal
      }
    }
  },
  created() {
    this.getRecieverList()
  },
  methods: {
    handleSelect() {
      console.log('handleSelect')
    },
    sort(name, order) {
      this.receiptEntity.receiptItems = _orderBy(this.activeItems, name, order)
    },
    async savePo(isDelete) {
      const value = _cloneDeep(this.innerValue)

      const poItemIds = this.selectedItems.map((i) => i.purchaseOrderItemId)

      value.parts.forEach((i) => {
        if (poItemIds.includes(i.purchaseOrderItemId) || i.isDeleted) {
          i.isDeleted = true
        }
      })

      const difference = DeepDiff.diff(this.purchaseOrder, value)

      if (!difference && this.validateError) {
        this.$notification.openNotificationWithType('danger', title, 'Validation errors. Please fix before saving')
        return false
      }

      try {
        this.$showSpinner('Saving...')

        let response
        response = await PartsControlService.putEntity(_cloneDeep(value), difference)
        this.$hideSpinner()
        this.$emit('close', 'You have successfully Cancelled the parts.')

        console.log(response)
      } catch (e) {
        console.log('error', e)
        this.$hideSpinner()
      }
    },
    async getRecieverList() {
      let recList = await PartsControlService.getOrderersDropdown()
      this.recieverList = recList
      const currentReciever = recList.find((i) => i.toLowerCase() === this.$userInfo?.displayName.toLowerCase())
      this.selectedReciever = currentReciever
    },
    handleRecieverChange(value) {
      this.selectedReciever = value
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/style/themes/default/_variables.scss';
.bb-primary-2 {
  border-bottom: 2px solid $primary;
}
.br-gray-2 {
  border-right: 1px solid #adb5bd;
}

.w-100 {
  display: flex;
  width: 100%;
}

.purchase-order-card-small {
  border: 1px solid #adb5bd;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
  height: 45px !important;
  align-items: center;
}

.purchase-order-card-header {
  border: 1px solid transparent;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

.purchase-order-card-small,
.purchase-order-card-header {
  display: grid;
  ////  # Description  Price  Qty
  grid-template-columns: 5% 55% 30% 10%;
}

.date-field {
  border: 1px solid #adb5bd;
  border-radius: 5px;
}

.item-qty {
  min-width: 40px;
  max-width: 40px;
  input {
    width: 100%;
  }
}
</style>
